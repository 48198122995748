<template>
    <div class="wallet-connect-button-container" :key="componentKey">
        <button
            @click="handleConnectToWalletBtnOnClick"
            class="wallet-connect-button line:bg-green line:hover:bg-[#07b44e]"
        >
            <img
                @error="
                    (e) =>
                        (e.target.src =
                            '/assets/imgs/components/navigator/tonlogo.png')
                "
                :src="`assets/imgs/${currentPlatform === 'telegram' ? 'components/navigator/tonlogo.png' : 'views/dailyCheckIn/logo_line.svg'}`"
                alt="TonConnect"
                class="tonconnect-icon"
            />
            {{ $t("deposit.connectWallet") }}
        </button>
    </div>
</template>

<script>
import { getTonConnectUIInstance } from "@/tonConnectSingleton";
import { beginCell, toNano, Address } from "@ton/ton";
import { mapGetters } from "vuex";
import TonWeb from "tonweb";
import api from "@/apis/task";
import { Web3Provider as w3 } from "@kaiachain/ethers-ext/v6";
import liff from "@line/liff";

export default {
    name: "WalletConnectButton",
    props: {
        currentTokenName: String, // 接受 currentTokenName 作为 prop
    },
    data() {
        return {
            // tonConnectUI: null,
            // isTonConnectUIAvailable: false,
            tonConnectUI: null,
            componentKey: 0,
            jettonWalletAddress: null,
            jettonMasterAddress: null, // 初始化为 null
            decimal: 9, // Decimal, default is 9
            checkAvailableTimer: null,
            checkConnectionTimer: null,
        };
    },
    computed: {
        ...mapGetters(["tokenList", "currentToken", "lineConfig", "platform"]),
        currentPlatform() {
            return this.platform;
        },
    },
    watch: {
        // 当 currentTokenName 改变时，更新 jettonMasterAddress
        currentTokenName(newTokenName, oldTokenName) {
            this.updateJettonMasterAddress(newTokenName);
        },
    },
    mounted() {
        this.componentKey++; // 更新键值以强制重新渲染
        this.initTonConnectUI();
        this.checkIsAvailable();
        this.updateJettonMasterAddress(this.currentTokenName);
    },
    methods: {
        handleConnectToWalletBtnOnClick() {
            this.connectToWallet((err) => {
                console.log(err);
            });
        },
        initTonConnectUI() {
            this.tonConnectUI = getTonConnectUIInstance({
                manifestUrl:
                    "https://www.tongifts.app/tonconnect-manifest.json",
            });
        },
        updateJettonMasterAddress(tokenName) {
            // 根据 tokenName 更新 jettonMasterAddress
            const tokenInfo = this.tokenList.find(
                (token) => token._id === tokenName,
            );
            if (
                tokenInfo &&
                tokenInfo.onChainInfo &&
                tokenInfo.onChainInfo.contract
            ) {
                console.log("tokenInfo.onChainInfo:", tokenInfo.onChainInfo);
                this.jettonMasterAddress = tokenInfo.onChainInfo.contract;
                this.decimal = tokenInfo.onChainInfo.decimals;
            } else {
                this.jettonMasterAddress = null;
                this.decimal = 9;
            }
            console.log(
                "update this.jettonMasterAddress:",
                this.jettonMasterAddress,
            );
        },
        async getWalletAddress() {
            console.log("this.tonConnectUI:", this.tonConnectUI);
            if (
                this.tonConnectUI &&
                this.tonConnectUI.connector &&
                this.tonConnectUI.connector._wallet &&
                this.tonConnectUI.connector._wallet.account &&
                this.tonConnectUI.connector._wallet.account.address
            ) {
                let myWalletAddress =
                    this.tonConnectUI.connector._wallet.account.address;
                console.log("myWalletAddress:", myWalletAddress);
                // this.fetchJettonWalletAddress(myWalletAddress); // 调用新方法
                if (
                    window.Telegram &&
                    window.Telegram.WebApp &&
                    window.Telegram.WebApp.initData
                ) {
                    const res = await api.tonConnect(myWalletAddress);
                    console.log("myWalletAddress: ", myWalletAddress);
                    console.log("tonconnect res: ", res);
                    if (res.data !== "Success") {
                        this.disconnect();
                        this.$message.error(
                            this.$t(
                                "messages.errors.walletHasBeenConnectedError",
                            ),
                            5,
                        );
                        return null;
                    } else {
                        return myWalletAddress;
                    }
                } else {
                    return myWalletAddress;
                }
            }
        },

        async fetchJettonWalletAddress(walletAddress) {
            // 获取用户的 Jetton Wallet 地址
            if (
                this.currentTokenName == "TON" ||
                this.currentToken.chainId != "TON"
            ) {
                this.jettonWalletAddress = null;
            } else {
                console.log("Wallet Address:", walletAddress);
                if (this.jettonWalletAddress == null) {
                    try {
                        this.jettonWalletAddress =
                            await this.getUserJettonWalletAddress(
                                walletAddress,
                            );
                    } catch (error) {
                        this.jettonWalletAddress = null;
                        console.error(
                            "getUserJettonWalletAddress error:",
                            error,
                        );
                    }

                    console.log(
                        "User Jetton Wallet Address:",
                        this.jettonWalletAddress,
                    );
                }
            }
        },

        getCurrentWalletInfo() {
            if (this.tonConnectUI) {
                const currentWallet = this.tonConnectUI.wallet;
                const currentWalletInfo = this.tonConnectUI.walletInfo;
                const currentAccount = this.tonConnectUI.account;
                const currentIsConnectedStatus = this.tonConnectUI.connected;

                console.log("Current Wallet:", currentWallet);
                console.log("Current Wallet Info:", currentWalletInfo);
                console.log("Current Account:", currentAccount);
                console.log(
                    "Current Connection Status:",
                    currentIsConnectedStatus,
                );

                // You can also return these values if you need to use them elsewhere
                return {
                    currentWallet,
                    currentWalletInfo,
                    currentAccount,
                    currentIsConnectedStatus,
                };
            } else {
                console.error("TonConnectUI not initialized");
                return null;
            }
        },

        generateQueryId() {
            // 生成一个 0 到 2^64 - 1 之间的随机数
            const high = Math.floor(Math.random() * 0xffffffff);
            const low = Math.floor(Math.random() * 0xffffffff);
            return (BigInt(high) << 32n) + BigInt(low);
        },
        // destroyTonConnectUI() {
        //     // Logic to clean up or reset the TonConnectUI instance
        //     this.tonConnectUI = null;
        //     this.isTonConnectUIAvailable = false;
        // },
        async connectToWallet(cb) {
            if (!liff.isInClient() && this.tonConnectUI) {
                try {
                    // await this.disconnect();
                    const connectedWallet =
                        await this.tonConnectUI.connectWallet();
                    console.log("Connected wallet:", connectedWallet);
                    if (connectedWallet) {
                        /// 调用 fetchJettonWalletAddress 方法
                        const validWalletAddress =
                            await this.getWalletAddress();
                        if (validWalletAddress) {
                            this.fetchJettonWalletAddress(validWalletAddress);
                            // 发出钱包连接成功事件，传递钱包地址
                            // this.$emit("onWalletConnected", validWalletAddress);
                            if (cb) {
                                cb(null, validWalletAddress);
                            }
                            this.$store.commit(
                                "setCurrentConnectedWalletAddress",
                                validWalletAddress,
                            );
                            this.$message.success(
                                this.$t("messages.success.walletConnected"),
                                5,
                            );
                        }
                    }
                } catch (error) {
                    console.error("Error connecting to wallet:", error);
                    // 处理连接错误
                    if (cb) {
                        cb(error);
                    }
                }
            }
            // Kaia wallet
            else {
                const provider =
                    this.lineConfig.dappPortalSDK?.getWalletProvider();

                const web3Provider = new w3(provider);
                const accounts = await web3Provider.send(
                    "kaia_requestAccounts",
                    [],
                );
                const address = accounts[0];
                this.$store.commit("setCurrentConnectedWalletAddress", address);
                this.$message.success(
                    this.$t("messages.success.walletConnected"),
                    5,
                );
            }
        },
        async sendTonTransaction(
            { address, amount, memo, bounceable = false },
            cb,
        ) {
            if (this.tonConnectUI) {
                try {
                    // 设置全局配置
                    this.tonConnectUI.uiOptions = {
                        actionsConfiguration: {
                            skipRedirectToWallet: "never", // 总是跳转到钱包
                            returnStrategy: "back", // 完成交易后返回到应用
                        },
                    };

                    const body = beginCell()
                        .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
                        .storeStringTail(memo) // write our text comment
                        .endCell();
                    const transaction = {
                        validUntil: Date.now() + 1000000,
                        // validUntil: Math.floor(new Date() / 1000) + 360,
                        messages: [
                            {
                                address: Address.parse(address).toString({
                                    bounceable,
                                    urlSafe: true,
                                }), //.toRawString(),//: Address.parse(address).toRawString() toString({bounceable: true, urlSafe: true}), // 目标地址
                                amount: toNano(amount.toString()).toString(), // 将金额转换为字符串
                                payload: body.toBoc().toString("base64"),
                            },
                        ],
                    };

                    console.log("send transaction go1:", transaction);
                    const result = await this.tonConnectUI.sendTransaction(
                        transaction,
                        {
                            modals: ["before", "success", "error"],
                            notifications: ["before", "success", "error"],
                        },
                    );
                    // const result = await this.tonConnectUI.sendTransaction(transaction, {
                    //     modals: ['before', 'success', 'error'],
                    //     notifications: ['before', 'success', 'error']
                    // });
                    // you can use signed boc to find the transaction
                    // const someTxData = await myAppExplorerService.getTransaction(result.boc);
                    // alert('Transaction was sent successfully', someTxData);
                    console.log("TON Transfer result:", result);
                    // this.$emit('onTonTransferSuccess', result);
                    // this.$emit("onDepositSuccess", amount);
                    if (cb) {
                        cb(null, amount);
                    }
                    console.log("sent onDepositSuccess:", amount);
                } catch (error) {
                    console.error("Error sending TON Transfer:", error);
                    // this.$emit("onTonTransferError", error);
                    if (cb) {
                        cb(error);
                    }
                }
            } else {
                console.error("TonConnectUI not initialized");
            }
        },
        async initiatePayment(
            { amount, token, targetAddress, memo, bounceable = false },
            cb,
        ) {
            if (token === "TON") {
                // 如果token是"TON"，调用普通TON转账的函数
                this.sendTonTransaction(
                    {
                        address: targetAddress,
                        amount,
                        memo,
                        bounceable,
                    },
                    cb,
                );
            } else {
                // 如果token是"UP"，调用TonUP token转账的函数
                if (this.jettonWalletAddress == null) {
                    // window.Telegram.WebApp.showPopup({
                    //     title: "No UP",
                    //     message: "You have 0 UP in your wallet",
                    // });
                    // this.$emit(
                    //     "onWarning",
                    //     "You have 0 " + token + " in your wallet",
                    // );
                    if (cb) {
                        cb("You have 0 " + token + " in your wallet");
                    }
                    return;
                }
                try {
                    const MyWalletAddress = Address.parse(
                        this.getWalletAddress(),
                    ); // 获取用户的钱包地址
                    const destinationAddress = Address.parse(targetAddress); // 接收者地址
                    // 尝试将amount转换为数字，如果失败，则发出警告并退出函数
                    const numericAmount = Number(amount);
                    if (isNaN(numericAmount)) {
                        console.error("Amount is not a valid number:", amount);
                        // this.$emit("onWarning", `Invalid amount: ${amount}`);
                        if (cb) {
                            cb(`Invalid amount: ${amount}`);
                        }
                        return;
                    }

                    // 根据decimal计算正确的金额，使用BigInt处理大整数
                    const myAmount = BigInt(
                        Math.floor(numericAmount * 10 ** this.decimal),
                    );

                    const forwardPayload = beginCell()
                        .storeUint(0, 32) // 0 opcode means we have a comment
                        .storeStringTail(memo)
                        .endCell();

                    const body = beginCell()
                        .storeUint(0xf8a7ea5, 32) // jetton transfer op code
                        .storeUint(this.generateQueryId(), 64) // query_id:uint64
                        .storeCoins(myAmount) // amount，根据Jetton的精度调整
                        .storeAddress(destinationAddress) // destination:MsgAddress
                        .storeAddress(MyWalletAddress) // response_destination:MsgAddress
                        .storeBit(0) // no custom payload
                        .storeCoins(1) // forward amount (if >0, will send notification message)
                        .storeBit(1) // we store forwardPayload as a reference
                        .storeRef(forwardPayload)
                        .endCell();

                    const transaction = {
                        validUntil: Math.floor(new Date() / 1000) + 360,
                        messages: [
                            {
                                // address: this.getWalletAddress(), // Jetton的主地址（智能合约地址）
                                // address: "EQCsVzpDGTUv9Pziin_CCtX_urrHbGW4P-y6HHcHpNaF4Y8u",
                                address: this.jettonWalletAddress,
                                amount: toNano("0.1").toString(), // 发送足够的TON来处理交易费用
                                payload: body.toBoc().toString("base64"), // 使用base64编码的payload
                            },
                        ],
                    };

                    // console.log("body:", body);
                    console.log(
                        "jettonWalletAddress:",
                        this.jettonWalletAddress,
                    );
                    // console.log("destinationAddress:", destinationAddress);
                    // console.log("Jetton message:", transaction);
                    // const result = await this.tonConnectUI.sendTransaction(transaction);
                    // this.getCurrentWalletInfo();
                    const result = await this.tonConnectUI.sendTransaction(
                        transaction,
                        {
                            modals: ["before", "success", "error"],
                            notifications: ["before", "success", "error"],
                        },
                    );
                    console.log("Jetton Transfer result:", result);
                    // this.$emit('onJettonTransferSuccess', result);
                    // this.$emit("onDepositSuccess", amount);
                    if (cb) {
                        cb(null, amount);
                    }
                } catch (error) {
                    console.error("Error sending Jetton Transfer:", error);
                    // this.$emit("onJettonTransferError", error);
                    if (cb) {
                        cb(error);
                    }
                }
            }
        },
        async getUserJettonWalletAddress(userWalletAddress) {
            const tonweb = new TonWeb(
                new TonWeb.HttpProvider(
                    "https://toncenter.com/api/v2/jsonRPC",
                    {
                        apiKey: "62505fc9e41175a1fb6570acc882255787129b20baa9ba5f8d0efe688fc4cc35",
                    },
                ),
            );

            // const jettonMinter = new TonWeb.token.jetton.JettonMinter(tonweb.provider, {address: this.jettonMasterAddress});
            // const userAddress = Address.parse(userWalletAddress);
            // const jettonWalletAddress = await jettonMinter.getJettonWalletAddress(userAddress);
            // return jettonWalletAddress.toString(true, true, true);
            const jettonMinter = new TonWeb.token.jetton.JettonMinter(
                tonweb.provider,
                { address: this.jettonMasterAddress },
            );
            const address = await jettonMinter.getJettonWalletAddress(
                new TonWeb.utils.Address(userWalletAddress),
            );
            console.log("address:", address);
            // It is important to always check that wallet indeed is attributed to desired Jetton Master:
            const jettonWallet = new TonWeb.token.jetton.JettonWallet(
                tonweb.provider,
                {
                    address: address,
                },
            );
            console.log("jettonWallet:", jettonWallet);
            try {
                const jettonData = await jettonWallet.getData();
            } catch (error) {
                console.error("jettonWallet getData error:", error);
                return null;
            }
            // if (jettonData.jettonMinterAddress.toString(false) !== new TonWeb.utils.Address(info.address).toString(false)) {
            //     throw new Error('jetton minter address from jetton wallet doesnt match config');
            // }
            // console.log("jettonData:", jettonData);
            // console.log('Jetton balance:', jettonData.balance.toString());
            console.log(
                "Jetton wallet address:",
                address.toString(true, true, true),
            );
            return address.toString(true, true, true);
        },
        async disconnect(cb) {
            try {
                if (this.tonConnectUI && !liff.isInClient()) {
                    await this.tonConnectUI.disconnect();
                }
                console.log("send disconnect");
                // this.$emit("onWalletDisconnected");
                this.$store.commit("setCurrentConnectedWalletAddress", null);
                if (cb) {
                    cb(null);
                }
            } catch (error) {
                console.error("Error disconnecting wallet:", error);
                if (cb) {
                    cb(error);
                }
            }
        },
        checkIsAvailable() {
            let isReady = false;
            const checkInterval = setInterval(() => {
                console.log("check is ready");
                if (this.tonConnectUI && !isReady) {
                    isReady = true;
                    this.$emit("onFunctionAvailable");
                    console.log("tonConnectUI is READY!");
                    this.checkIsConnected();
                }
            }, 300);
            this.checkAvailableTimer = checkInterval;
            setTimeout(() => {
                clearInterval(checkInterval);
            }, 5000);
        },
        checkIsConnected() {
            const checkInterval = setInterval(() => {
                const connectedWalletAddress =
                    this.tonConnectUI &&
                    this.tonConnectUI.connector &&
                    this.tonConnectUI.connector._wallet &&
                    this.tonConnectUI.connector._wallet.account &&
                    this.tonConnectUI.connector._wallet.account.address;
                if (connectedWalletAddress) {
                    console.log(
                        "wallet already connected: ",
                        connectedWalletAddress,
                    );
                    this.$store.commit(
                        "setCurrentConnectedWalletAddress",
                        connectedWalletAddress,
                    );
                    clearInterval(checkInterval);
                }
            }, 300);
            this.checkConnectionTimer = checkInterval;
            setTimeout(() => {
                clearInterval(checkInterval);
            }, 5000);
        },
    },
    beforeDestroy() {
        clearInterval(this.checkAvailableTimer);
        clearInterval(this.checkConnectionTimer);
    },
};
</script>

<style scoped>
.wallet-connect-button-container {
    width: 100%;
}

.wallet-connect-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0088cc; /* Telegram blue color */
    color: white; /* White text */
    padding: 5px 8px;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
    border: none;
    font-weight: bold;
    width: 100%;
    box-sizing: border-box;
    height: 48px;
    line-height: 32px;
}

/* For hover effect */
.wallet-connect-button:hover {
    background-color: #006699;
}

.tonconnect-icon {
    width: 24px; /* Size for the TON logo */
    height: 24px;
    margin-right: 8px;
}
</style>
