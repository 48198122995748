<template>
    <a-drawer
        class="invitation-panel"
        placement="bottom"
        :closable="false"
        :visible="visible"
        @close="hide"
        height="auto"
        :getContainer="false"
    >
        <!-- Close Button -->
        <button @click="hide" class="absolute right-3 top-4 rounded-full">
            <svg-icon
                class="text-[#F0F0F0]"
                type="mdi"
                size="24"
                :path="mdiCloseCircle"
            >
            </svg-icon>
        </button>

        <!-- Content -->
        <div class="inline-flex flex-col items-center gap-3 text-[#111111]">
            <div class="text-lg font-bold">
                {{ title }}
            </div>
            <div class="text-center">
                {{ subTitle }}
            </div>
        </div>

        <!-- Share Methods -->
        <div class="flex w-full items-center justify-between gap-2">
            <div
                v-for="(method, index) in renderShareMethods()"
                :key="index"
                v-if="method.canRender"
                class="flex flex-1 flex-col items-center gap-2"
            >
                <button
                    :disabled="!currentUserName || !inviteLink"
                    :class="{
                        'opacity-30': !currentUserName || !inviteLink,
                    }"
                    @click="method.handleClick"
                    class="flex h-12 w-12 items-center justify-center rounded-full bg-primary-15"
                >
                    <img :src="method.src" alt="" class="h-6 w-6" />
                </button>
                <span class="text-xs text-[#111]">{{ method.title }}</span>
            </div>
        </div>

        <!-- Share to TG -->
        <button
            v-if="showMainBtn"
            @click="shareToChat"
            class="flex w-11/12 items-center justify-center gap-2.5 rounded-lg bg-primary px-6 py-3"
        >
            <img
                src="assets/imgs/icons/invitationPanel_share.svg"
                alt=""
                class="size-6"
            />
            <span class="text-lg font-bold text-[#fff]">{{
                $t("receiveGifts.newInvitationPanel.shareBtnText")
            }}</span>
        </button>

        <InvitationQRCodeModal
            :isActive="isInvitationQRCodeModalActive"
            @close="isInvitationQRCodeModalActive = false"
            :isForNewUser="true"
            v-if="showQrCode"
        />

        <a-modal
            class="gif-modal"
            v-model="gifModalVisible"
            centered
            @ok="handleUnderstand"
            :cancelButtonProps="{ style: { display: 'none' } }"
            :okText="$t('receiveGifts.newInvitationPanel.iUnderstand')"
        >
            <p class="py-4 text-center text-base text-[#333]">
                <b>{{ $t("receiveGifts.newInvitationPanel.gifModalTitle") }}</b
                >:
                {{ $t("receiveGifts.newInvitationPanel.gifModalDescription") }}:
            </p>
            <img
                src="assets/imgs/utils/gifs/demo1_cut.gif"
                alt="Demo GIF"
                class="border-2 border-dashed border-[#4dabb0] p-1"
            />
            <a-checkbox
                class="dont-show-checkbox"
                :checked="dontShowAgain"
                @change="checkDontShowAgain"
            >
                {{ $t("receiveGifts.newInvitationPanel.dontShowAgainText") }}
            </a-checkbox>
        </a-modal>

        <toast ref="toast"></toast>
    </a-drawer>
</template>

<script>
import i18n from "@/locale";
import { handleCopyText } from "@/utils/common";
import InvitationQRCodeModal from "@/views/receiveGifts/components/invitationPanel/components/InvitationQRCodeModal.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiCloseCircle } from "@mdi/js";
import { mapGetters } from "vuex";
import Toast from "@/components/Toast.vue";
import liff from "@line/liff";

export default {
    name: "InvitationPanel",
    props: {
        title: {
            type: String,
            required: true,
        },
        subTitle: {
            type: String,
            required: true,
        },
        showQrCode: {
            type: Boolean,
            default: true,
        },
        shareLink: {
            type: String,
            required: true,
        },
        copyText: {
            type: String,
            required: true,
        },
        inviteLink: {
            type: String,
            required: true,
        },
        showMainBtn: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        SvgIcon,
        InvitationQRCodeModal,
        Toast,
    },
    data: () => ({
        visible: false,
        mdiCloseCircle,
        isInvitationQRCodeModalActive: false,
        isNavigatorShareAvailable: false,
        showGifModal: false,
        gifModalVisible: false,
        dontShowAgain: false,
    }),
    computed: {
        ...mapGetters(["platform", "userInfo"]),
        currentPlatform() {
            return this.platform;
        },
        currentUserName() {
            return (
                (this.userInfo &&
                    this.userInfo.user &&
                    this.userInfo.user.tg_info &&
                    this.userInfo.user.tg_info.first_name) ||
                null
            );
        },
    },
    methods: {
        renderShareMethods() {
            return [
                {
                    title: i18n.t("receiveGifts.newInvitationPanel.copyLink"),
                    src: `assets/imgs/icons/invitationPanel_link${this.currentPlatform === "telegram" ? "" : "_line"}.svg`,
                    canRender: true,
                    handleClick: async () =>
                        this.currentPlatform === "telegram"
                            ? window.open(this.shareLink)
                            : await liff.shareTargetPicker([
                                  { type: "text", text: this.shareLink },
                              ]),
                },
                {
                    title: i18n.t("receiveGifts.newInvitationPanel.copyText"),
                    src: `assets/imgs/icons/invitationPanel_copy${this.currentPlatform === "telegram" ? "" : "_line"}.svg`,
                    canRender: true,
                    handleClick: this.copyLink,
                },
                {
                    title: i18n.t("receiveGifts.newInvitationPanel.saveQRCode"),
                    src: `assets/imgs/icons/invitationPanel_image${this.currentPlatform === "telegram" ? "" : "_line"}.svg`,
                    canRender: this.showQrCode,
                    handleClick: this.displayQRCode,
                },
                {
                    title: i18n.t("receiveGifts.newInvitationPanel.more"),
                    src: `assets/imgs/icons/invitationPanel_more${this.currentPlatform === "telegram" ? "" : "_line"}.svg`,
                    handleClick: this.handleMoreBtnClick,
                    canRender: this.isNavigatorShareAvailable,
                },
            ];
        },
        show() {
            this.visible = true;
        },
        hide() {
            this.visible = false;
        },
        triggerToast(type, message) {
            this.$bus.$emit("show-toast", {
                type: type,
                message: message,
            });
        },
        showToast(type, message) {
            this.triggerToast(type, message);
        },
        async copyLink() {
            await handleCopyText(this.copyText, () => {
                this.showToast(
                    "success",
                    this.$t("receiveGifts.newInvitationPanel.copiedText"),
                );
            });
        },
        displayQRCode() {
            this.isInvitationQRCodeModalActive = true;
        },
        async handleMoreBtnClick() {
            try {
                await navigator.share({
                    text: this.copyText,
                    url: this.inviteLink,
                });
            } catch (err) {
                console.error("navigator share error: ", err);
            }
        },
        async shareToChat() {
            if (localStorage.getItem("dontShowGifModal") === "true") {
                await this.continueConfirm();
            } else {
                if (
                    window.Telegram.WebApp.platform === "macos" ||
                    window.Telegram.WebApp.platform === "ios"
                ) {
                    // 如果是 iOS 或 macOS，显示 GIF
                    this.gifModalVisible = true;
                } else {
                    await this.continueConfirm();
                }
            }
        },
        getStartappParams() {
            const [_, param1, param2] = this.inviteLink
                .split("?")[1]
                .split("_");
            return { param1, param2 };
        },
        async continueConfirm() {
            if (this.currentPlatform === "telegram") {
                if (window.Telegram.WebApp.platform === "macos") {
                    const activeLink = this.userInfo.user.inviteLink;
                    window.Telegram.WebApp.disableClosingConfirmation();
                    window.Telegram.WebApp.openTelegramLink(activeLink);
                    window.Telegram.WebApp.close();
                } else {
                    window.Telegram.WebApp.switchInlineQuery(
                        `envelope:invite:${this.inviteLink ? `${this.getStartappParams().param1}:${this.getStartappParams().param2}` : null}`,
                        ["users", "bots", "groups", "channels"],
                    );
                }
            } else {
                await liff.shareTargetPicker([
                    { type: "text", text: this.shareLink },
                ]);
            }
        },
        async handleUnderstand() {
            this.gifModalVisible = false;
            if (this.dontShowAgain) {
                localStorage.setItem("dontShowGifModal", "true");
            }
            await this.continueConfirm();
        },
        checkDontShowAgain(e) {
            this.dontShowAgain = e.target.checked;
        },
    },
    mounted() {
        if (window.navigator && window.navigator.share) {
            this.isNavigatorShareAvailable = true;
        }
    },
};
</script>

<style lang="scss">
.invitation-panel {
    .ant-drawer {
        &-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1.5rem;
        }
    }
}

.gif-modal {
    .ant-modal {
        &-content {
            border-radius: 8px;
        }

        &-body {
            text-align: center;
            padding-bottom: 8px;
        }

        &-footer {
            border-radius: 0 0 8px 8px;
            border-top: 0;
            padding-bottom: 20px;

            & > div {
                text-align: center;
            }
        }
    }

    .ant-btn-primary {
        font-size: 1rem;
        padding: 10px 20px;
        height: unset;
    }
}

.dont-show-checkbox {
    &.ant-checkbox-wrapper {
        padding-top: 16px;

        & > span:last-child {
            padding-left: 4px;
        }
    }
}
</style>
